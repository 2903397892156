body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


::-webkit-scrollbar {
    width: 4px; /* Scrollbar genişliği */
    background-color: "#166cff ";
}

/* Scrollbar yatay durumunda */
::-webkit-scrollbar-track {
    background: transparent; /* Scrollbar izleme arka planı */
}

/* Scrollbar kaydırıcısı */
::-webkit-scrollbar-thumb {
    background-color: "#166cff "; /* Scrollbar kaydırıcısı rengi */
    border-radius: 4px; /* Scrollbar kaydırıcısı köşe yarıçapı */
}

/* Scrollbar kaydırıcısı hover durumunda */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(
        0,
        0,
        0,
        0.4
    ); /* Scrollbar kaydırıcısı hover rengi */
}
